import React, { ReactElement } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { PageHeaderComposition } from '@csv/styleguide/src/features/page-header/page-header';
import Heading from '@csv/styleguide/src/elements/heading/heading';
import Section from '@csv/styleguide/src/elements/section/section';
import Container from '@csv/styleguide/src/elements/container/container';
import { Link } from 'gatsby';
import styled from 'styled-components';

const AdressGrid = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));

  .address {
    min-width: 280px;
    padding: 0.75rem;
    border: 1px solid #e3f1fe;
    border-radius: 4px;
  }

  .address__title {
    font-size: 14px;
    line-height: 1.5rem;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0 0 1.5rem 0;
  }

  .address__body {
    font-size: 16px;
  }

  .address__route {
    font-size: 16px;
  }
`;

const ContentBody = styled.div`
  max-width: 70ch;
  margin: 0 auto;
`;

function PracticeTablePage(): ReactElement {
  return (
    <Layout>
      <SEO
        title="Contact &amp; locaties"
        description="Kom in contact met ons en bekijk de adressen van de verschillende accomodaties van CSV Handbal."
      />
      <PageHeaderComposition title="Contact &amp; locaties" />
      <Container>
        <Section>
          <ContentBody>
            <Heading as="h2" variant="h2">
              Contact opnemen
            </Heading>
            <p>
              Heb je een vraag, idee of opmerking over de website, neem dan
              contact op met de webmaster:{' '}
              <a
                href="mailto:webmaster@csvhandbal.nl"
                target="_blank"
                rel="noopener noreferrer"
              >
                webmaster@csvhandbal.nl
              </a>
            </p>
            <p>
              Voor algemene vragen kun je contact opnemen met de secretaris via{' '}
              <a
                href="mailto:secretaris@csvhandbal.nl"
                target="_blank"
                rel="noopener noreferrer"
              >
                secretaris@csvhandbal.nl
              </a>
              .
            </p>
            <p>
              Voor meer informatie over onze vereniging kun je altijd
              telefonisch contact opnemen met Inge Oussoren via 0640112749.
            </p>
            <p>&nbsp;</p>
            <p>
              Ben je op zoek naar contactgegevens van individuele bestuurs- of
              commissieleden? Kijk dan op onze{' '}
              <Link to="over-csv/bestuur">bestuurspagina</Link>.
            </p>
          </ContentBody>
        </Section>
        <Section>
          <ContentBody>
            <Heading as="h2" variant="h2">
              Adressen
            </Heading>
            <AdressGrid>
              <div className="address">
                <h3 className="address__title">Postadres</h3>
                <div className="address__body">
                  C.S.V. Handbal
                  <br />
                  Postbus 154
                  <br />
                  1900 AD Castricum
                  <br />
                </div>
              </div>
              <div className="address">
                <h3 className="address__title">
                  Clubhuis en buitenwedstrijden
                </h3>
                <div className="address__body">
                  Sportpark Wouterland
                  <br />
                  Van Haerlemlaan 27
                  <br />
                  1901 JM CASTRICUM
                  <br />
                </div>
                <a
                  href="https://www.google.com/maps/dir//C.S.V.+Handbal,+Van+Haerlemlaan+27,+1901+JM+Castricum/@52.5613739,4.6604951,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x47c5f70cd371419f:0x3bdcf944882240b3!2m2!1d4.661993!2d52.5607877!3e0"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="address__route"
                  title="Route plannen naar Sportpark Wouterland"
                >
                  Route plannen
                </a>
              </div>

              <div className="address">
                <h3 className="address__title">
                  Sporthal zaalwedstrijden (Castricum)
                </h3>
                <div className="address__body">
                  Sportcentrum De Bloemen
                  <br />
                  De Bloemen 71
                  <br />
                  1902 GT Castricum
                  <br />
                </div>
                <a
                  href="https://www.google.com/maps/dir//Sportcentrum+de+Bloemen/@52.5517253,4.649813,13z/data=!4m9!4m8!1m0!1m5!1m1!1s0x47c5f7622630f8d9:0xedd9217ef14fb5f!2m2!1d4.6848323!2d52.5516783!3e0"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="address__route"
                  title="Route plannen naar Sportcentrum de Bloemen"
                >
                  Route plannen
                </a>
              </div>

              <div className="address">
                <h3 className="address__title">
                  Sporthal zaalwedstrijden (Limmen)
                </h3>
                <div className="address__body">
                  Sportcentrum De Enterij
                  <br />
                  Middenweg 3A
                  <br />
                  1906 AP Limmen
                  <br />
                </div>
                <a
                  href="https://www.google.com/maps/dir//Sportcentrum+D'Enterij+B.V.,+Middenweg+3b,+1906+AP+Limmen/@52.5690981,4.6917887,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x47c5f82b158cf1df:0x1a6a1d992f525290!2m2!1d4.6948028!2d52.5684881!3e0"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="address__route"
                  title="Route plannen naar Sportcentrum De Enterij"
                >
                  Route plannen
                </a>
              </div>
            </AdressGrid>
          </ContentBody>
        </Section>
      </Container>
    </Layout>
  );
}

export default PracticeTablePage;
